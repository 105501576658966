import styled from 'styled-components';

export const FeedsCardContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  .feeds-topic-header {
    .feeds-topic-owner-info {
      height: 2.1875rem;
      display: flex;
      margin: 0.5rem 0;
      img {
        width: 2.1875rem;
        height: 2.1875rem;
        border-radius: 50%;
      }
      .feeds-topic-owner-username {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1rem;
        span:first-child {
          font-size: 0.875rem;
          color: #333;
        }
        span:last-child {
          font-size: 0.75rem;
          color: #939393;
        }
      }
    }
    .feeds-topic-title {
      margin-top: 10px;
      h4 {
        width: 100%;
        margin-bottom: 0;
        height: 0.875rem;
        line-height: 0.875rem;
        font-size: 0.875rem;
        color: #555;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .feeds-topic-location-cost {
      height: 20px;
      line-height: 20px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      p:first-child {
        color: #5c5c5c;
        i {
          margin-right: 0.3rem;
        }
      }

      .feeds-topic-cost {
        color: ${props => props.theme.primaryColor};
        font-size: 1rem;
      }
    }
  }
  .feeds-topic-main {
    margin-top: 10px;
    /*
    * 行高 h
    * 最大行数 n
    * ...更多容器的宽 w
    * 字号 f
    */
    @-webkit-keyframes width-change {
      0%,
      100% {
        width: 320px;
      }
      50% {
        width: 260px;
      }
    }
    .ellipsis {
      position: relative;
      max-height: 67.2px; /* h*n */
      line-height: 22.4px; /* h */
      overflow: hidden;
      -webkit-animation: width-change 8s ease infinite;
    }
    .ellipsis-container {
      position: relative;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; /* n */
      font-size: 70px; /* w */
      color: transparent;
    }
    .ellipsis-content {
      color: #5c5c5c;
      display: inline;
      vertical-align: top;
      font-size: 14px; /* f */
    }
    .ellipsis-ghost {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      width: 100%;
      height: 100%;
      color: #000;
    }
    .ellipsis-ghost:before {
      content: '';
      display: block;
      float: right;
      width: 50%;
      height: 100%;
    }
    .ellipsis-placeholder {
      content: '';
      display: block;
      float: right;
      width: 50%;
      height: 67.2px; /* h*n */
    }
    .ellipsis-more {
      position: relative;
      float: right;
      font-size: 14px; /* f */
      width: 50px; /* w */
      height: 22.4px; /* h */
      margin-top: -22.4px; /* -h */
      color: rgb(59, 122, 219);
    }
    .feeds-topic-image-square {
      display: flex;
      flex-wrap: wrap;
      margin: -1.5px;
      .feeds-topic-image-square-item {
        flex: 0 0 33%;
        box-sizing: border-box; /*开启border-box，将marin，padding，border算入height和width*/
        border: 1.5px solid transparent;
        height: ${() => {
          const screenW =
            window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;
          const width = screenW > 414 ? 414 : screenW;
          return (width - 20) * 0.333 + 'px';
        }};
        img {
          width: 100%;
          height: 100%;
        }
      }
      .feeds-topic-image-square-item:first-child:nth-last-child(4)
        ~ .feeds-topic-image-square-item:nth-child(2) {
        margin-right: 33%;
      }
    }

    .feeds-topic-image {
      margin: 0.5rem 0;
      .feeds-topic-image-one {
        width: 11.25rem;
        height: 11.25rem;
        img {
          max-width: 11.25rem;
          max-height: 11.25rem;
        }
      }
    }
  }
`;
